<template>
    <section class="list">
        <tool-bar>
            Campaigns
            <template slot="buttons">
                <div class="level-item">
                    <button class="button"
                            v-bind:class="{ 'is-loading': isLoading }"
                            v-on:click="refreshEnquiries">
                        <span class="icon is-small">
                            <font-awesome-icon icon="redo-alt"></font-awesome-icon>
                        </span>
                        <span>Refresh</span>
                    </button>
                </div>
            </template>
        </tool-bar>
    </section>
</template>

<script>
    import ToolBar from '../layouts/ToolBar'

    export default {
        name: 'List',
        components: {ToolBar}
    }
</script>

<style lang="scss" scoped>

</style>
